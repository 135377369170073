import React, { useState } from "react";
import { ListGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { createGroupScorecard, createGroupScorecardPlayers } from "../util/db";
import { useRouter } from "../util/router";
import ScorecardPlayerSelectRow from "./ScorecardPlayerSelectRow";

function ScorecardPlayerSelect(props) {
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const eventId = props.eventId;
  const auth = props.auth;
  const uid = auth.user ? auth.user.uid : undefined;
  const router = useRouter();

  const handleConfirmPlayers = () => {
    if (selectedPlayers.length !== 0) {
      setConfirmDisabled(true);

      const dataGroupScorecard = {
        event_id: eventId,
        card_setup_user: uid,
      };

      //create group scorecard in group_scorecard table and return id
      createGroupScorecard(dataGroupScorecard).then((groupScorecardId) => {
        let index = 1;

        //loop through selected players, and add to group_scorecard_players table
        selectedPlayers.forEach((player) => {
          const dataGroupScorecardPlayers = {
            group_scorecard_id: groupScorecardId.id,
            event_entries_id: player,
            order: index,
          };

          //create group scorecard players
          createGroupScorecardPlayers(dataGroupScorecardPlayers).then(() => {
            //redirect to scorecard

            //if gameId=2, redirect to throw_scorecard
            if (props.gameId === 2) {
              router.push(`/throws_scorecard?scorecard=${groupScorecardId.id}`);
            } else {
              router.push(`/scorecard?scorecard=${groupScorecardId.id}`);
            }
          });

          index += 1;
        });
      });
    } else {
      alert("Please select players to create a scorecard.");
    }
  };

  const availablePlayers = props.items;

  return (
    <div>
      <ListGroup as="ul">
        {availablePlayers.map((item, index) => (
          <ScorecardPlayerSelectRow
            eventId={eventId}
            auth={auth}
            key={index}
            item={item}
            selectedPlayers={selectedPlayers}
            setSelectedPlayers={setSelectedPlayers}
          />
        ))}
      </ListGroup>
      <br />
      <div className="text-center">
        <Button
          disabled={confirmDisabled}
          className="w-100"
          onClick={handleConfirmPlayers}
        >
          Create Scorecard
        </Button>
      </div>
    </div>
  );
}

export default ScorecardPlayerSelect;
