import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

function EventPageHeader({
  eventName,
  eventCourse,
  eventDate,
  eventGameName,
  eventCourseId,
}) {
  return (
    <Container>
      <header className="SectionHeader text-center mb-0">
        <h1 className="font-weight-bold mt-4">{eventName}</h1>
        <p className="mb-0">
          <Link to={`/venue?id=${eventCourseId}`}>{eventCourse}</Link>
        </p>
        <p className="mb-0">{eventDate}</p>
        <p className="mb-0">{eventGameName}</p>
      </header>
    </Container>
  );
}

export default EventPageHeader;
