import React from "react";
import SectionHeader from "./SectionHeader";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { createCourse, useStates } from "../util/db";
import { useState } from "react";

function AdminPageCourseAdd({ auth }) {
  const [courseName, setCourseName] = useState("");
  const [selectedStateId, setSelectedStateId] = useState("");
  const [courseCity, setCourseCity] = useState("");
  const [courseAddress, setCourseAddress] = useState("");
  const [courseZip, setCourseZip] = useState("");

  //get states
  const {
    data: states,
    // status: itemsStatus,
    // error: itemsError,
  } = useStates();

  const handleFormCourseNameChange = (e) => {
    setCourseName(e.target.value);
  };

  const handleFormStateChange = (e) => {
    setSelectedStateId(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (courseName === "") {
      alert("Please enter a course name");
    } else if (selectedStateId === "") {
      alert("Please select a state");
    } else if (courseCity === "") {
      alert("Please enter a city");
    } else if (courseAddress === "") {
      alert("Please enter an address");
    } else if (courseZip === "") {
      alert("Please enter a zip code");
    } else {
      const courseToAdd = {
        course_name: courseName,
        added_by: auth.user.id,
        state_id: selectedStateId,
        city: courseCity,
        address: courseAddress,
        zip_code: courseZip,
      };

      createCourse(courseToAdd).then((response) => {
        alert("Course added");
        setCourseName("");
        setSelectedStateId("");
        setCourseCity("");
        setCourseAddress("");
        setCourseZip("");
      });
    }
  };

  return (
    <>
      <SectionHeader
        title={"Add Course"}
        // subtitle={props.subtitle}
        size={1}
        spaced={true}
        className="text-center mt-3 mb-1"
      />
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formCourseName">
          <Form.Label>Course Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter course name"
            value={courseName}
            onChange={handleFormCourseNameChange}
          />
        </Form.Group>
        <Form.Group controlId="formAddress">
          <Form.Label>Address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter address"
            value={courseAddress}
            onChange={(e) => setCourseAddress(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formCity">
          <Form.Label>City</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter city"
            value={courseCity}
            onChange={(e) => setCourseCity(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formState">
          <Form.Label>State</Form.Label>
          <select
            className="form-control"
            value={selectedStateId}
            onChange={handleFormStateChange}
          >
            <option value="">Select a state</option>
            {states &&
              states.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.state_name}
                </option>
              ))}
          </select>
        </Form.Group>
        <Form.Group controlId="formZip">
          <Form.Label>Zip</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter zip"
            value={courseZip}
            onChange={(e) => setCourseZip(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
}

export default AdminPageCourseAdd;
