import React from "react";
import Meta from "./../components/Meta";
import { useRouter } from "../util/router";
import { useCourse, useEventsByCourse } from "../util/db";
import Container from "react-bootstrap/Container";
import SectionHeader from "../components/SectionHeader";
import { Row, Col } from "react-bootstrap";
import VenueInformation from "../components/VenueInformation";
import VenueEvents from "../components/VenueEvents";
import HrMobileOnly from "../components/HrMobileOnly";

function VenuePage() {
  const router = useRouter();
  const venueId = router.query.id;

  const {
    data: course,
    status: courseStatus,
    error: courseError,
  } = useCourse(venueId);

  const {
    data: events,
    // status: eventsStatus,
    // error: eventsError,
  } = useEventsByCourse(venueId);

  if (courseStatus === "loading") {
    return <div>Loading...</div>;
  }

  if (courseError) {
    return <div>Error loading course information</div>;
  }

  return (
    <>
      <Meta title="Venue Information" />

      <Container>
        <SectionHeader
          size={1}
          spaced={true}
          className="text-center mt-4"
          title={course ? course.course_name : "Loading..."}
        />
        <Row>
          <Col xs={12} md={6}>
            <VenueInformation course={course} />
          </Col>
          <HrMobileOnly />
          <Col xs={12} md={6}>
            <VenueEvents events={events} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default VenuePage;
