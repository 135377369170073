import React from "react";

function HrMobileOnly() {
  return (
    <div className="d-md-none d-block w-100 my-3">
      <hr />
    </div>
  );
}

export default HrMobileOnly;
