import React from "react";
import SectionHeader from "./SectionHeader";
import EventCreateDisplayFeesTable from "./EventCreateDisplayFeesTable";

function EventCreateFormStep7({
  formName,
  formDate,
  formIsReplay,
  formIsGolfScoring,
  formGame,
  games,
  selectedVenueName,
  layouts,
  payoutMethods,
  selectedDivisions,
  setSelectedDivisions,
}) {
  return (
    <>
      <SectionHeader
        title={"Summary"}
        subtitle={"Please review the event details before creating"}
        size={1}
        spaced={true}
        className="text-center mt-3 mb-2"
      />
      <hr />
      <div className="mb-2">
        <p className="text-center">Event Name: {formName}</p>
      </div>
      <div className="mb-2">
        <p className="text-center">Event Date: {formDate}</p>
      </div>
      <div className="mb-2">
        <p className="text-center">
          Allow Replays: {formIsReplay ? "Yes" : "No"}
        </p>
      </div>
      <div className="mb-2">
        <p className="text-center">
          Golf Scoring: {formIsGolfScoring ? "Yes" : "No"}
        </p>
      </div>
      <div className="mb-2">
        <p className="text-center">
          Game:{" "}
          {games &&
            games.map((game) => (game.id === formGame ? game.name : ""))}
        </p>
      </div>
      <div className="mb-2">
        <p className="text-center">Venue: {selectedVenueName}</p>
      </div>
      <br />
      <div className="mb-2">
        <p className="text-center">Divisions </p>
        <EventCreateDisplayFeesTable
          layouts={layouts}
          payoutMethods={payoutMethods}
          selectedDivisions={selectedDivisions}
          setSelectedDivisions={setSelectedDivisions}
        />
      </div>
      <hr />
    </>
  );
}

export default EventCreateFormStep7;
