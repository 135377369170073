import React from "react";
import { useEventEntriesIsDnf } from "../util/db";

function ScorecardSummaryTable5x4ColorsTotals({
  numReds,
  numWhites,
  numBlues,
  numYellows,
  total,
  eventEntryId,
}) {
  //   useevententriesisdnf
  const {
    data: isDnf,
    // status: isDnfStatus,
    // error: isDnfError,
  } = useEventEntriesIsDnf(eventEntryId);

  return (
    <tr>
      <th className="text-center" style={{ border: "2px solid #2D083F" }}>
        <span
          style={{
            backgroundColor: "red",
            color: "white",
            padding: "0.2em 0.6em",
            borderRadius: "0.25em",
            display: "inline-block",
            border: "1px solid black",
          }}
        >
          {numReds}
        </span>
      </th>
      <th className="text-center" style={{ border: "2px solid #2D083F" }}>
        <span
          style={{
            backgroundColor: "white",
            color: "black",
            padding: "0.2em 0.6em",
            borderRadius: "0.25em",
            display: "inline-block",
            border: "1px solid black",
          }}
        >
          {numWhites}
        </span>
      </th>
      <th className="text-center" style={{ border: "2px solid #2D083F" }}>
        <span
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "0.2em 0.6em",
            borderRadius: "0.25em",
            display: "inline-block",
            border: "1px solid black",
          }}
        >
          {numBlues}
        </span>
      </th>
      <th className="text-center" style={{ border: "2px solid #2D083F" }}>
        <span
          style={{
            backgroundColor: "yellow",
            color: "black",
            padding: "0.2em 0.6em",
            borderRadius: "0.25em",
            display: "inline-block",
            border: "1px solid black",
          }}
        >
          {numYellows}
        </span>
      </th>
      <th className="text-center" style={{ border: "2px solid #2D083F" }}>
        <span
          style={{
            // backgroundColor: "yellow",
            color: "black",
            padding: "0.2em 0.6em",
            borderRadius: "0.25em",
            display: "inline-block",
            // border: "1px solid black",
          }}
        >
          {isDnf && isDnf.is_dnf ? "DNF" : total}
        </span>
      </th>
    </tr>
  );
}

export default ScorecardSummaryTable5x4ColorsTotals;
