import React from "react";
import { useCoursesAdmin } from "../util/db";
import { Link } from "react-router-dom";
import AdminPageDisplayCoursesCourseName from "./AdminPageDisplayCoursesCourseName";
import AdminPageDisplayCoursesCourseAddress from "./AdminPageDisplayCoursesCourseAddress";
import AdminPageDisplayCoursesCourseCity from "./AdminPageDisplayCoursesCourseCity";
import AdminPageDisplayCoursesZip from "./AdminPageDisplayCoursesZip";
import AdminPageDisplayCoursesState from "./AdminPageDisplayCoursesState";

function AdminPageDisplayCourses() {
  const {
    data: items,
    status: itemsStatus,
    // error: itemsError,
  } = useCoursesAdmin();

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Course Name</th>
          <th>Address</th>
          <th>City</th>
          <th>State</th>
          <th>Zip</th>
          <th>Map Link</th>
          <th>Added By?</th>
        </tr>
      </thead>
      <tbody>
        {itemsStatus !== "loading" &&
          items &&
          items.length > 0 &&
          items.map((item, index) => (
            <tr key={index}>
              <AdminPageDisplayCoursesCourseName item={item} />
              <AdminPageDisplayCoursesCourseAddress item={item} />
              <AdminPageDisplayCoursesCourseCity item={item} />
              <AdminPageDisplayCoursesState item={item} />
              <AdminPageDisplayCoursesZip item={item} />
              <td>
                <a
                  href={`https://www.google.com/maps/place/${item.address}+${item.city}+${item.states.state_name}+${item.zip_code}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Map
                </a>
              </td>
              <td>
                <Link to={`/player?id=${item.added_by}`}>{item.added_by}</Link>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default AdminPageDisplayCourses;
