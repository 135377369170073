import React from "react";
import { useUsersNamePublic } from "../util/db";
import AdminPagePlayersRowNamePublicBox from "./AdminPagePlayersRowNamePublicBox";

function AdminPagePlayersRowNamePublic({ item }) {
  // useUsersNamePublic
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useUsersNamePublic(item.id);

  if (itemsStatus === "loading") {
    return <td>...</td>;
  } else if (itemsStatus === "error") {
    return <td>Error: {itemsError.message}</td>;
  } else if (itemsStatus === "success" && items) {
    return <AdminPagePlayersRowNamePublicBox id={item.id} name={items.name} />;
  } else if (itemsStatus === "success" && !items) {
    return <AdminPagePlayersRowNamePublicBox id={item.id} name={""} />;
  } else {
    return <td></td>;
  }
}

export default AdminPagePlayersRowNamePublic;
