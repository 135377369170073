import React from "react";
import { Card } from "react-bootstrap";

function VenueInformation({ course }) {
  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    `${course.address}, ${course.city}, ${course.states.state_name}, ${course.zip_code}`
  )}`;

  return (
    <Card className="mb-2" border="primary" style={{ border: "none" }}>
      <Card.Header
        as="h5"
        className="d-flex justify-content-between align-items-center"
        style={{ background: "#2D083F", color: "#ffffff" }}
      >
        <Card.Title style={{ color: "#ffffff" }}>Venue Information</Card.Title>
      </Card.Header>
      <Card.Body style={{ background: "#FFFFFF" }}>
        <Card.Text>
          <strong>Name:</strong> {course.course_name}
        </Card.Text>
        <Card.Text>
          <strong>Address:</strong> {course.address}
        </Card.Text>
        <Card.Text>
          <strong>City:</strong> {course.city}
        </Card.Text>
        <Card.Text>
          <strong>State:</strong> {course.states.state_name}
        </Card.Text>
        <Card.Text>
          <strong>ZIP Code:</strong> {course.zip_code}
        </Card.Text>
        <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">
          View on Google Maps
        </a>
      </Card.Body>
      <Card.Header
        as="h5"
        className="d-flex justify-content-between align-items-center"
        style={{ background: "#2D083F", color: "#ffffff" }}
      />
    </Card>
  );
}

export default VenueInformation;
