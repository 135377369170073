import React from "react";
import Card from "react-bootstrap/Card";
import ScorecardCreateComponent from "./ScorecardCreateComponent";

function ScorecardCreateScorecardCard({ item, index, eventId, auth, gameId }) {
  const resultCard = (
    <Card
      key={index}
      className="mb-2"
      border="primary"
      style={{ border: "none" }}
    >
      <Card.Header
        as="h5"
        className="d-flex justify-content-between align-items-center"
        style={{ background: "#2D083F", text: "white" }}
      >
        <Card.Title style={{ color: "#ffffff" }}>Create Scorecard</Card.Title>
      </Card.Header>

      <Card.Body
        className="d-flex justify-content-center align-items-center"
        style={{ background: "#FFFFFF" }}
      >
        <ScorecardCreateComponent
          eventEntryId={item.id}
          eventId={eventId}
          auth={auth}
          gameId={gameId}
        />
      </Card.Body>
      <Card.Header
        as="h5"
        className="d-flex justify-content-between align-items-center"
        style={{ background: "#2D083F", text: "white" }}
      ></Card.Header>
    </Card>
  );

  return resultCard;
}

export default ScorecardCreateScorecardCard;
