import React from "react";
import { Link } from "react-router-dom";
import { useScorecardHoles } from "../util/db";

function ViewScorecardRow({ player, eventId }) {
  //use hook usescorecardholes
  const {
    data: holes,
    // status:holesStatus,
    // error:holesError
  } = useScorecardHoles(player.event_entries.id);

  //if holes exists, set holes to holes
  if (holes !== null && holes !== undefined) {
    var holeScoresData = holes;
  }

  //get total score for this row
  //if holescoresdata exists, reduce holescoresdata to get total score, else 0
  const thisRowsScoreTotal = holeScoresData
    ? holeScoresData.reduce((a, b) => {
        return a + b.score;
      }, 0)
    : 0;

  //get number of 5s
  //if holescoresdata exists, filter holescoresdata for scores of 5, else 0
  const numFives = holeScoresData
    ? holeScoresData.filter((score) => {
        return score.score === 5;
      }).length
    : 0;

  // const thisRowsScoreTotal = holeScoresData.reduce((a,b) => {
  //     return a + b.score
  // },0)

  // const numFives = holeScoresData.filter((score) => {
  //     return score.score === 5
  // }).length

  return (
    <tr>
      {/* <td style={{ width: "auto", tableLayout: "auto", border: "2px solid #2D083F" }}> */}
      <td
        className="align-middle sticky-col"
        style={{
          width: "30px",
          tableLayout: "auto",
          border: "2px solid #2D083F",
          color: "#2D083F",
          position: "sticky",
          left: 0,
          fontWeight: "bold",
          fontStyle: "italic",
        }}
      >
        <Link
          to={`/player?id=${player.event_entries.user_id}`}
          style={{ color: "#2D083F" }}
        >
          {player.event_entries.users_public.name}
        </Link>
      </td>

      {holeScoresData?.map((hole, holeIndex) =>
        hole.score === 5 ? (
          <td
            key={holeIndex}
            className="text-center align-middle"
            style={{
              width: "30px",
              tableLayout: "auto",
              border: "2px solid #2D083F",
            }}
          >
            <Link
              to={`/holescoreedits?hole=${hole.hole_scores_id}&event=${eventId}`}
              style={{
                // color: (holeIndex+1)%4===1 ? "#FF0000" : (holeIndex+1)%4===2 ? "#000000" : (holeIndex+1)%4===3 ? "#0000FF" : (holeIndex+1)%4===0 ? "#000000" : "#FFFFFF",
                // backgroundColor: (holeIndex+1)%4===1 ? "#FFFFFF" : (holeIndex+1)%4===2 ? "#FFFFFF" : (holeIndex+1)%4===3 ? "#FFFFFF" : (holeIndex+1)%4===0 ? "#FFFF00" : "#FFFF00",
                color: "#2d083f",
                // backgroundColor: "#ffffff",
                fontWeight: "bold",
              }}
            >
              &#9734;
            </Link>
            {/* &#9734; */}
          </td>
        ) : (
          <td
            key={holeIndex}
            className="text-center align-middle"
            style={{
              width: "30px",
              tableLayout: "auto",
              border: "2px solid #2D083F",
              color: "#2D083F",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            <Link
              to={`/holescoreedits?hole=${hole.hole_scores_id}&event=${eventId}`}
              style={{ color: "#2D083F" }}
            >
              {hole.score}
            </Link>
            {/* {hole.score} */}
          </td>
        )
      )}

      {/* <td className="align-bottom" style={{ width: "auto", tableLayout: "auto", border: "2px solid #2D083F" }}> */}
      <td
        className="align-middle text-center"
        style={{
          width: "30px",
          tableLayout: "auto",
          border: "2px solid #2D083F",
          color: "#2D083F",
          fontWeight: "bold",
          fontStyle: "italic",
        }}
      >
        {player.event_entries.is_dnf === true ? "DNF" : thisRowsScoreTotal}
        {/* {thisRowsScoreTotal} */}
      </td>

      <td
        className="align-middle text-center"
        style={{
          width: "30px",
          tableLayout: "auto",
          border: "2px solid #2D083F",
          color: "#2D083F",
          fontWeight: "bold",
          fontStyle: "italic",
        }}
      >
        {numFives}
      </td>
    </tr>
  );
}

export default ViewScorecardRow;
