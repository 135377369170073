import React, { useState, useRef } from "react";
import { updateCourseState } from "../util/db";
import { useStates } from "../util/db";

function AdminPageDisplayCoursesState({ item }) {
  const [isEditing, setIsEditing] = useState(false);
  const [courseState, setCourseState] = useState(item.state_id);
  const [isSaved, setIsSaved] = useState(false);
  const longPressTimeout = useRef(null);
  const { data: states, isLoading } = useStates();

  const handleLongPressStart = () => {
    longPressTimeout.current = setTimeout(() => {
      setIsEditing(true);
    }, 500); // 500ms for long press duration
  };

  const handleLongPressEnd = () => {
    clearTimeout(longPressTimeout.current);
  };

  const handleSelectChange = (e) => {
    setCourseState(parseInt(e.target.value, 10)); // Ensure courseState is a number
  };

  const handleBlur = () => {
    if (item.state_id !== courseState) {
      onSave(courseState).then(() => {
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 3000); // Reset the saved state after 3 seconds
      });
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  async function onSave(newCourseState) {
    await updateCourseState(item.id, newCourseState).then(() => {
      console.log("Course state updated");
    });
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <td
      onTouchStart={handleLongPressStart}
      onTouchEnd={handleLongPressEnd}
      onMouseDown={handleLongPressStart}
      onMouseUp={handleLongPressEnd}
      onMouseLeave={handleLongPressEnd}
      style={{ whiteSpace: "nowrap" }}
    >
      {isEditing ? (
        <select
          value={courseState}
          onChange={handleSelectChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
          style={{
            backgroundColor: isSaved ? "#d4edda" : "white",
            width: "100%",
            maxWidth: "200px",
          }} // Set max-width to prevent layout shift
        >
          {states.map((state) => (
            <option key={state.id} value={state.id}>
              {state.state_name}
            </option>
          ))}
        </select>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: "200px",
          }}
        >
          <span style={{ flexGrow: 1 }}>
            {states.find((state) => state.id === courseState)?.state_name ||
              "Unknown State"}
          </span>
          <span style={{ width: "20px", display: "inline-block" }}>
            {isSaved && <span style={{ color: "green" }}>✔️</span>}
          </span>
        </div>
      )}
    </td>
  );
}

export default AdminPageDisplayCoursesState;
