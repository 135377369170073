import React from "react";
import { useState } from "react";
import { updateUserIsTd, updateUserIsNotTd } from "../util/db";
import { Button } from "react-bootstrap";

function AdminPagePlayersRowHost({ id, is_td }) {
  const [isEditing, setIsEditing] = useState(false);
  const [isHost, setIsHost] = useState(is_td);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);

    //if "yes" is selected, and the user is not already a host then update the user to be a host
    //if "no" is selected, and the user is already a host then update the user to not be a host

    if (is_td === false && isHost === true) {
      updateUserIsTd(id).then((res) => {
        setIsEditing(false);
      });
    } else if (is_td === true && isHost === false) {
      updateUserIsNotTd(id).then((res) => {
        setIsEditing(false);
      });
    } else {
      setIsEditing(false);
    }
  };

  const handleCancelClick = () => {
    // setNewName(is_td);
    setIsEditing(false);
  };

  return (
    <td>
      {isEditing ? (
        <>
          <select
            value={isHost}
            onChange={(e) => setIsHost(e.target.value === "true")}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          <Button
            style={{ background: "#4D81F4", fontWeight: "bold", border: "0" }}
            onClick={handleSaveClick}
            className="mt-2"
          >
            Save
          </Button>
          <Button
            style={{ background: "#4D81F4", fontWeight: "bold", border: "0" }}
            onClick={handleCancelClick}
            className="mt-2"
          >
            Cancel
          </Button>

          {/* <button onClick={handleSaveClick}>Save</button> */}
          {/* <button onClick={handleCancelClick}>Cancel</button> */}
        </>
      ) : (
        <>
          {isHost ? "Yes" : "No"}
          <span
            onClick={handleEditClick}
            style={{ cursor: "pointer", marginLeft: "5px" }}
          >
            ✏️
          </span>
        </>
      )}
    </td>
  );
}

export default AdminPagePlayersRowHost;
