import React from "react";
import { Table } from "react-bootstrap";

function VenueEventsTable({ events }) {
  return (
    <Table striped bordered hover responsive className="mt-3">
      <thead>
        {events.length !== 0 && (
          <tr>
            <th>Date</th>
            <th>Event Name</th>
          </tr>
        )}
      </thead>
      <tbody>
        {events &&
          events.map((event) => (
            <tr key={event.id}>
              <td>{new Date(event.date).toLocaleDateString("en-US")}</td>
              <td>
                <a href={`/event?event=${event.id}`}>{event.name}</a>
              </td>
            </tr>
          ))}
        {events.length === 0 && (
          <tr>
            <td colSpan="2">No events yet</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default VenueEventsTable;
