import React from "react";
import AdminPagePlayersRowName from "./AdminPagePlayersRowName";
import AdminPagePlayersRowNamePublic from "./AdminPagePlayersRowNamePublic";
import AdminPagePlayersRowHost from "./AdminPagePlayersRowHost";

function AdminPagePlayersRow({ item }) {
  return (
    <tr>
      <AdminPagePlayersRowName item={item} />
      <AdminPagePlayersRowNamePublic item={item} />
      <td>{item.email}</td>
      <AdminPagePlayersRowHost id={item.id} is_td={item.is_td} />
      <td>{item.phonenumber}</td>
    </tr>
  );
}

export default AdminPagePlayersRow;
