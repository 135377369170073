import React from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import EventChat from "./EventChat";
import EventScorecards from "./EventScorecards";
import DisplayLeaderboard from "./DisplayLeaderboard";
import EventSection from "./EventSection";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";

function EventPageTabs({
  isIncompleteCards,
  isCheckedInAlready,
  canCheckIn,
  eventId,
  uid,
  isLoggedIn,
  isEventComplete,
  auth,
  gameId,
  posts,
  eventName,
  scoringOrderGolf,
}) {
  const chatTitle = useMemo(
    () => (posts?.length > 0 ? `Chat (${posts.length})` : "Chat"),
    [posts]
  );
  const [activeKey, setActiveKey] = useState("scorecards");

  useEffect(() => {
    if (isEventComplete) {
      setActiveKey("players");
    }
  }, [isEventComplete]);

  return (
    <>
      <Container style={{ fontWeight: "bold" }}>
        <Tabs
          activeKey={activeKey}
          onSelect={(k) => setActiveKey(k)}
          className="mb-3 mt-3"
          fill
          variant="pills"
        >
          <Tab eventKey="scorecards" title="Score">
            <EventScorecards
              isIncompleteCards={isIncompleteCards}
              isCheckedInAlready={isCheckedInAlready}
              canCheckIn={canCheckIn}
              eventId={eventId}
              uid={uid}
              isLoggedIn={isLoggedIn}
              isEventComplete={isEventComplete}
              auth={auth}
              gameId={gameId}
            />
          </Tab>
          <Tab eventKey="chat" title={chatTitle}>
            <EventChat eventId={eventId} uid={uid} posts={posts} />
          </Tab>
          <Tab eventKey="scoreboard" title="Scorecards">
            <DisplayLeaderboard
              bg="white"
              textColor="dark"
              size="md"
              bgImage=""
              bgImageOpacity={1}
              title={eventName}
              subtitle=""
              eventId={eventId}
              scoringOrderGolf={scoringOrderGolf}
              isEventComplete={isEventComplete}
            />
          </Tab>
          <Tab eventKey="players" title="Leaderboard">
            {scoringOrderGolf !== null ? (
              <EventSection
                bg="white"
                textColor="dark"
                size="md"
                bgImage=""
                bgImageOpacity={1}
                title={eventName}
                subtitle=""
                eventId={eventId}
                scoringOrderGolf={scoringOrderGolf}
                isEventComplete={isEventComplete}
              />
            ) : (
              <div>Loading...</div>
            )}
          </Tab>
        </Tabs>
      </Container>
    </>
  );
}

export default EventPageTabs;
