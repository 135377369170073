import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../img/kpleagues_badge_purple.png";

function HowToSection() {
  return (
    <Container>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} lg={6} className="">
          {/* link to www.kpleagues.com */}
          <a href="https://www.kpleagues.com" target="_blank" rel="noreferrer">
            <img src={logo} alt="KP Leagues" className="img-fluid" />
          </a>
        </Col>
        <Col xs={12} lg={6}>
          {/* centered header */}
          {/* bold h1 */}
          <h1 style={{ fontWeight: "bold" }} className="text-center mt-3">
            Welcome to DGscores.app
          </h1>
          <h3 className="text-center">Quick Start Guide</h3>

          <ol>
            <li>
              First time? Click "Login with Google" or{" "}
              <Link to="/auth/signup">
                {""}Create an Account{""}
              </Link>{" "}
              and enter your email. Then head over to your inbox and confirm
              your account.
            </li>
            <li>
              Already have an account?
              <Link to="/auth/signin"> Login </Link>
            </li>
            <li>Choose the event you are playing from above under "Events"</li>
            <li>Click "Check-In HERE"</li>
            <li>Choose your division and click "Register"</li>
            <li>
              One player on your card will select the names of your cardmates
              from the "available players" list and click create scorecard
            </li>
            <li>Disc Golf!</li>
          </ol>
        </Col>
      </Row>
    </Container>
  );
}

export default HowToSection;
