import React, { StrictMode } from "react";
import "./../styles/global.scss";
import NavbarCustom from "./../components/NavbarCustom";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import SignupPage from "./signup";
import PaymentPage from "./payment";
import EventPage from "./event";
import PlayerPage from "./player";
import ScorecardPage from "./scorecard";
import HoleScoreEdits from "../components/HoleScoreEdits";
import DisplayScorecardSection from "./display_scorecard";
import RegisterPage from "./register";
import GroupScorecardView from "../components/GroupScorecardView";
import EventManagePage from "./EventManagePage";
import AddToScorecardPage from "./AddToScorecardPage";
import EventCreatePage from "./EventCreatePage";
import EventChat from "./event_chat";
import RegisterGuestPage from "./register_guest";
import AdminPage from "./admin";
import RemoveFromScorecardPage from "./RemoveFromScorecardPage";
import DnfScorecardPage from "./DnfScorecardPage";
import EventsPage from "./EventsPage";
import EventDeletePage from "./EventDeletePage";
import ShowHideTotalsScorecardPage from "./ShowHideTotalsScorecardPage";
import EditLayoutPage from "./edit_layout";
import EventViewPageLD from "./EventViewPageLD";
import ThrowScorecardPage from "./throw_scorecard";
import ThrowsScorecardPage from "./throws_scorecard";
import ScorecardOrderPage from "./scorecard_order";
import VenuePage from "./venue";

function App(props) {
  return (
    <StrictMode>
      <QueryClientProvider>
        <AuthProvider>
          <Router>
            <>
              <NavbarCustom variant="light" expand="md" />
              <Switch>
                <Route exact path="/" component={IndexPage} />

                <Route exact path="/about" component={AboutPage} />

                <Route exact path="/events" component={EventsPage} />

                <Route exact path="/faq" component={FaqPage} />

                <Route exact path="/contact" component={ContactPage} />

                <Route exact path="/pricing" component={PricingPage} />

                <Route exact path="/profile" component={DashboardPage} />

                <Route exact path="/auth/:type" component={AuthPage} />

                <Route
                  exact
                  path="/settings/:section"
                  component={SettingsPage}
                />

                <Route exact path="/legal/:section" component={LegalPage} />

                <Route exact path="/purchase/:plan" component={PurchasePage} />

                <Route exact path="/signup" component={SignupPage} />

                <Route exact path="/payment" component={PaymentPage} />

                <Route exact path="/event" component={EventPage} />

                <Route exact path="/player" component={PlayerPage} />

                <Route exact path="/venue" component={VenuePage} />

                <Route
                  exact
                  path="/display_scorecard"
                  component={DisplayScorecardSection}
                />

                <Route
                  exact
                  path="/view_scorecard"
                  component={GroupScorecardView}
                />

                <Route exact path="/scorecard" component={ScorecardPage} />

                <Route
                  exact
                  path="/scorecard/addplayer"
                  component={AddToScorecardPage}
                />

                <Route
                  exact
                  path="/scorecard/removeplayer"
                  component={RemoveFromScorecardPage}
                />

                <Route
                  exact
                  path="/scorecard/dnfplayer"
                  component={DnfScorecardPage}
                />

                <Route
                  exact
                  path="/scorecard/showhidetotals"
                  component={ShowHideTotalsScorecardPage}
                />

                <Route
                  exact
                  path="/scorecard/order"
                  component={ScorecardOrderPage}
                />

                <Route
                  exact
                  path="/holescoreedits"
                  component={HoleScoreEdits}
                />

                <Route exact path="/register" component={RegisterPage} />

                <Route
                  exact
                  path="/register_guest"
                  component={RegisterGuestPage}
                />

                <Route exact path="/event_manage" component={EventManagePage} />

                <Route exact path="/event_create" component={EventCreatePage} />

                <Route exact path="/event_view" component={EventViewPageLD} />

                <Route
                  exact
                  path="/event_chat/:event_id"
                  component={EventChat}
                />

                <Route exact path="/admin" component={AdminPage} />

                <Route exact path="/event_delete" component={EventDeletePage} />

                <Route exact path="/edit_layout" component={EditLayoutPage} />

                <Route
                  exact
                  path="/throw_scorecard"
                  component={ThrowScorecardPage}
                />

                <Route
                  exact
                  path="/throws_scorecard"
                  component={ThrowsScorecardPage}
                />

                <Route component={NotFoundPage} />
              </Switch>
              <hr style={{ width: "90%", backgroundColor: "#000000" }} />
              <Footer
                bg="white"
                textColor="dark"
                size="md"
                bgImage=""
                bgImageOpacity={1}
              />
            </>
          </Router>
        </AuthProvider>
      </QueryClientProvider>
    </StrictMode>
  );
}

export default App;
