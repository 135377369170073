import AdminPageDisplayCourses from "./AdminPageDisplayCourses";
import HrCustom from "./HrCustom";
import AdminPageCourseAdd from "./AdminPageCourseAdd";

function AdminPageCourses({ auth }) {
  return (
    <>
      <h6 className="text-center">Long press to edit</h6>
      <AdminPageDisplayCourses />
      <HrCustom />
      <AdminPageCourseAdd auth={auth} />
    </>
  );
}

export default AdminPageCourses;
