import React from "react";
import { useState } from "react";
import { updateUserName } from "../util/db";
import { Button } from "react-bootstrap";

function AdminPagePlayersRowNamePublicBox({ id, name }) {
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(name);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    updateUserName(id, newName)
      .then((res) => {
        setIsEditing(false);
      })
      .catch((error) => {
        console.error("Error updating name:", error);
      });
  };

  const handleCancelClick = () => {
    setNewName(name);
    setIsEditing(false);
  };

  return (
    <td>
      {isEditing ? (
        <>
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <Button
            style={{ background: "#4D81F4", fontWeight: "bold", border: "0" }}
            onClick={handleSaveClick}
            className="mt-2"
          >
            Save
          </Button>
          <Button
            style={{ background: "#4D81F4", fontWeight: "bold", border: "0" }}
            onClick={handleCancelClick}
            className="mt-2"
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          {name}
          <span
            onClick={handleEditClick}
            style={{ cursor: "pointer", marginLeft: "5px" }}
          >
            ✏️
          </span>
        </>
      )}
    </td>
  );
}

export default AdminPagePlayersRowNamePublicBox;
