import React, { useState, useRef } from "react";
import { updateCourseAddress } from "../util/db";

function AdminPageDisplayCoursesCourseAddress({ item }) {
  const [isEditing, setIsEditing] = useState(false);
  const [courseAddress, setCourseAddress] = useState(item.address);
  const [isSaved, setIsSaved] = useState(false);
  const longPressTimeout = useRef(null);

  const handleLongPressStart = () => {
    longPressTimeout.current = setTimeout(() => {
      setIsEditing(true);
    }, 500); // 500ms for long press duration
  };

  const handleLongPressEnd = () => {
    clearTimeout(longPressTimeout.current);
  };

  const handleInputChange = (e) => {
    setCourseAddress(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (item.course_address !== courseAddress) {
      onSave(courseAddress).then(() => {
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 3000); // Reset the saved state after 3 seconds
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  async function onSave(newCourseAddress) {
    await updateCourseAddress(item.id, newCourseAddress).then(() => {
      console.log("Course address updated");
    });
  }

  return (
    <td
      onTouchStart={handleLongPressStart}
      onTouchEnd={handleLongPressEnd}
      onMouseDown={handleLongPressStart}
      onMouseUp={handleLongPressEnd}
      onMouseLeave={handleLongPressEnd}
      style={{ whiteSpace: "nowrap" }}
    >
      {isEditing ? (
        <input
          type="text"
          value={courseAddress}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
          style={{
            backgroundColor: isSaved ? "#d4edda" : "white",
            width: "100%",
            maxWidth: "200px",
          }} // Set max-width to prevent layout shift
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: "200px",
          }}
        >
          <span style={{ flexGrow: 1 }}>{courseAddress}</span>
          <span style={{ width: "20px", display: "inline-block" }}>
            {isSaved && <span style={{ color: "green" }}>✔️</span>}
          </span>
        </div>
      )}
    </td>
  );
}

export default AdminPageDisplayCoursesCourseAddress;
