import React from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import VenueEventsTable from "./VenueEventsTable";

function VenueEvents({ events }) {
  let eventsPast = events
    ? events.filter((event) => event.is_event_complete === true)
    : [];

  let eventsUpcoming = events
    ? events.filter((event) => event.is_event_complete === false)
    : [];

  return (
    <Card className="mb-2" border="primary" style={{ border: "none" }}>
      <Card.Header
        as="h5"
        className="d-flex justify-content-between align-items-center"
        style={{ background: "#2D083F", color: "#ffffff" }}
      >
        <Card.Title style={{ color: "#ffffff" }}>Events</Card.Title>
      </Card.Header>
      <Card.Body style={{ background: "#FFFFFF" }}>
        <Tabs defaultActiveKey="upcoming" id="events-tab">
          <Tab eventKey="upcoming" title="Upcoming Events">
            <VenueEventsTable events={eventsUpcoming} title="Upcoming Events" />
          </Tab>
          <Tab eventKey="past" title="Past Events">
            <VenueEventsTable events={eventsPast} title="Past Events" />
          </Tab>
        </Tabs>
      </Card.Body>
      <Card.Header
        as="h5"
        className="d-flex justify-content-between align-items-center"
        style={{ background: "#2D083F", color: "#ffffff" }}
      />
    </Card>
  );
}

export default VenueEvents;
