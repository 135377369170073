import React from "react";
// import "./FeaturesSection.scss";
import { useUpcomingEvents } from "../util/db";
import FormAlert from "./FormAlert";
import { Container } from "react-bootstrap";
import EventsTable from "./EventsTable";

function EventsSection(props) {
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useUpcomingEvents();

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      {itemsStatus !== "loading" && items && items.length > 0 && (
        <>
          <Container className="mt-4">
            <h1 className="font-weight-bold mb-4 text-center">Events</h1>
            <EventsTable events={items} />
          </Container>
        </>
      )}
      {/* if there are any events, show <hr> */}
      {items && items.length > 0 && (
        <hr style={{ width: "90%", backgroundColor: "#000000" }} />
      )}
    </>
  );
}

export default EventsSection;
