import React, { useState, useRef } from "react";
import { updateCourseName } from "../util/db";

// filepath: /c:/Users/PK/Documents/code/dg/src/components/AdminPageDisplayCoursesCourseName.js
function AdminPageDisplayCoursesCourseName({ item }) {
  const [isEditing, setIsEditing] = useState(false);
  const [courseName, setCourseName] = useState(item.course_name);
  const [isSaved, setIsSaved] = useState(false);
  const longPressTimeout = useRef(null);

  const handleLongPressStart = () => {
    longPressTimeout.current = setTimeout(() => {
      setIsEditing(true);
    }, 500); // 500ms for long press duration
  };

  const handleLongPressEnd = () => {
    clearTimeout(longPressTimeout.current);
  };

  const handleInputChange = (e) => {
    setCourseName(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (item.course_name !== courseName) {
      onSave(courseName).then(() => {
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 3000); // Reset the saved state after 3 seconds
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  async function onSave(newCourseName) {
    await updateCourseName(item.id, newCourseName).then(() => {
      console.log("Course name updated");
    });
  }

  return (
    <td
      onTouchStart={handleLongPressStart}
      onTouchEnd={handleLongPressEnd}
      onMouseDown={handleLongPressStart}
      onMouseUp={handleLongPressEnd}
      onMouseLeave={handleLongPressEnd}
      style={{ whiteSpace: "nowrap" }}
    >
      {isEditing ? (
        <input
          type="text"
          value={courseName}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
          style={{
            backgroundColor: isSaved ? "#d4edda" : "white",
            width: "100%",
            maxWidth: "200px",
          }} // Set max-width to prevent layout shift
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: "200px",
          }}
        >
          <span style={{ flexGrow: 1 }}>{courseName}</span>
          <span style={{ width: "20px", display: "inline-block" }}>
            {isSaved && <span style={{ color: "green" }}>✔️</span>}
          </span>
        </div>
      )}
    </td>
  );
}

export default AdminPageDisplayCoursesCourseName;
