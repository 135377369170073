import React from "react";
import { usePlayersAdmin } from "../util/db";
import AdminPagePlayersRow from "./AdminPagePlayersRow";

function AdminPagePlayers() {
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = usePlayersAdmin();

  return (
    <>
      {itemsError && <div className="mb-3">...</div>}
      <table className="table">
        <thead>
          <tr>
            <th>Player Name</th>
            <th>Player Name (Public)</th>
            <th>Email</th>
            <th>LD?</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {itemsStatus !== "loading" &&
            items &&
            items.length > 0 &&
            items.map((item, index) => (
              <AdminPagePlayersRow key={index} item={item} />
            ))}
        </tbody>
      </table>
    </>
  );
}

export default AdminPagePlayers;
